import React from 'react';
import { Container } from 'reactstrap';
import { Layout } from '../components';

const NotFoundPage = () => (
  <Layout>
    <Container className="error-page-container">
      <h1>Not found</h1>
      <h2>The route you have tried to access does not exist yet.</h2>
    </Container>
  </Layout>
);

export default NotFoundPage;
